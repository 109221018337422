<template>
  <div class="min-h-screen pt-6 pb-6 flex flex-col bg-white">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-6">
      <div class="flex-shrink-0 flex justify-center">
      <span class="inline-flex">
        <span class="sr-only">Суши Хаус</span>
        <img class="h-12 w-auto" :src="require('@/assets/logo.png')" alt="Суши Хаус">
      </span>
      </div>
      <div class="py-6">
        <div class="text-center">
          <p class="mt-2 text-base text-gray-500">
            Мы рады приветствовать вас на нашем сайте!<br>
            Для того чтобы ознакомиться с нашим меню и оформить доставку, выберите, пожалуйста, город:
          </p>
          <div class="mt-6">
            <a :href="uuUrl" class="block text-4xl font-bold mb-4 text-brand hover:underline">Улан-Удэ</a>
            <div class="text-sm font-medium text-gray-500 mb-2">
              Телефон в Улан-Удэ: <a href="tel:+73012555777" class="text-brand hover:underline">+7 (3012) 555-777</a>
            </div>
            <a :href="irkutskUrl" class="block text-4xl font-bold mb-4 text-brand hover:underline">Иркутск</a>
            <div class="text-sm font-medium text-gray-500">
              Телефон в Иркутске: <a href="tel:+73952480999" class="text-brand hover:underline">+7 (3952) 480-999</a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  computed: {
    irkutskUrl() {
      return 'http://irkutsk.sushi-house.online'
    },

    uuUrl() {
      return this.getFullUrl('http://uu.sushi-house.online')
    },

    angarskUrl() {
      return this.getFullUrl('http://irkutsk.sushi-house.online')
    },
  },

  methods: {
    getFullUrl(prefix) {
      return `${prefix}${this.$route.fullPath}`
    },
  },
}
</script>
